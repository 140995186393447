/* mint css - start */

@font-face{
  font-family: "Clash Display Bold";
  src: url("../fonts/ClashDisplay-Semibold.otf");
}

@font-face{
  font-family: "StyreneBLC";
  src: url("../fonts/StyreneBLC-Regular.otf");
}

@font-face{
  font-family: "Mazzard";
  src: url("../fonts/MazzardM-Medium.ttf");
}



a {
  text-decoration: none;
  color: white;
}

.mint-container {
  height: 100vh;
  overflow: hidden;
  background-image: url('../images/mint-background.png');
  background-size: cover;
  background-position: center;
  padding-inline: 0px;
  /* overflowY: hidden; */
  inset: 0;
}

.left-image-container {
  float: left;
  left: 0;
  top: 0;
  bottom: 0;
}

.right-image-container {
  float: right;
  right: 0;
  top: 0;
  bottom: 0;
}

.disconect-btn-component {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: spacZe-between;
  flex: none;
  order: 1;
  border-spacing: 0 10px;
}

.modal-center-image {
  width: 160px;
  height: 160px;
}

.modal-heading {
  font-family: Clash Display Bold;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ca-header {
  font-family: Clash Display Bold;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
}

.div1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* color: white; */
  margin-inline: 80px;
}

.div1.show {
  height: 150;
  transition: all 0.7s ease;
  text-align: center;
}

.div2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  margin-top: 10px;
}

.div2.show {
  height: 430px;
  transition: all 0.7s ease;
  text-align: center;
}
.div1.hide,
.div2.hide {
  text-align: center;
  height: 0;
  overflow: hidden;
  transition: all 0.7s ease;
}

.modal-heading {
  font-family: Clash Display Bold;
  font-style: normal;
  font-weight: 400px;
  font-size: 32px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
}

.text-box{
  background-color: #1A1A1A;
  padding: 3px 10px;
  margin-bottom: 10px;
}

span.text-span {
  font-family: StyreneBLC;
  font-weight: 400;
  font-size: 10px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.08em;
  margin-top: 5px;
  color: #ffffff;
}

.metamask-notice {
  font-family: Mazzard;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin-top: 5px;
  color: #E6AB29;
}

.text-eth-value {
  font-family: Mazzard;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #E6AB29;
  margin-top: 4px;
}

.button-row-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: row;
  width: 150px;
  margin-top: 10px;
  margin-block: 5px;
}

.button-decrease-increase {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #E6AB29;
  flex: none;
  order: 0;
  flex-grow: 0;
  background: black;
  color: #ffffff;
  font-size: 22px;
}

.gray-text {
  font-family: StyreneBLC;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.08em;
  padding-bottom: 5px;
  text-transform: uppercase;
  color: #999999;
}

.yellow-text {
  font-family: StyreneBLC;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #E6AB29;
  padding-top: 5px;
}

.white-text {
  font-family: Mazzard;
  font-weight: 600;
  font-size: 20px;
  line-height: 12px;
  text-align: center;
  color: #ffffff;
  margin-top: 4px;
}

.button-decrease-increase:hover {
  background-color: #4d4d4d;
}

.button-decrease-increase:active {
  background-color: #ffffff;
  color: black;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.connect-button{
  font-family: Clash Display Bold;
  font-style: normal;
  font-weight: 600px;

  background-color: transparent;
  color: white;
  font-size: 14px;
  padding: 10px 30px;
  border-radius: 0px;
  border: 2px solid white;
  margin: 0px 0px;
  cursor: pointer;
}

.app-button{
  font-family: Clash Display Bold;
  font-style: normal;
  font-weight: 600px;
  background-color: transparent;
  color: white;
  font-size: 14px;
  padding: 10px 80px;
  border-radius: 0px;
  border: 2px solid white;
  margin: 0px 0px;
  cursor: pointer;
}

.gray-para{
  color: white;
}

.side-image {
  height: 100vh;
  width: 100%;
}

.mint-value {
  font-family: Clash Display Bold;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 45px;
  text-align: center;
  letter-spacing: 0.04em;
  margin-bottom: 5px;
  color: #E6AB29;
}

.label-disconect {
  font-family: Clash Display Bold;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #b70d0d;
  cursor: pointer;
}

.footer-text{
  font-family: StyreneBLC;
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #ffffff;
}

.info-text{
  font-family: Clash Display Bold;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #bcbcbc;
}

.info-box{
  background-color: #1A1A1A;
  padding: 3px 10px;
  margin-bottom: 0px;
}

.span-disconect {
  font-size: 20px;
  margin-inline: 5px;
}

.modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  order: 2;
  width: inherit;
  padding-block: 10px;
  border-top: 1px solid #333333;
  color: white;
}

.modal-footer-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 0%;
  margin-top: 10px;
}

.page-wrap > div.mint-container {
  max-width: 100% !important;
  padding: 0 !important;
}
.label-disconect {
  padding-top: 20px;
}
/* mint css - end */
@media (max-width: 1280px) {
  .left-image-container,
  .right-image-container {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1400px) {
  .modal-container .modal {
    scale: 0.9;
  }
}
@media (min-width: 720px) and (max-width: 1024px) {
  .modal-container .modal {
    scale: 0.9;
  }
}
@media (max-width: 719px) {
  .modal-container .modal {
    scale: 0.8;
  }
}
@media (max-width: 440px) {
  .modal-container .modal {
    scale: 0.7;
  }
}

nav ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0px 20px;
  padding: 20px;
}

nav li {
  flex: 1;
}

