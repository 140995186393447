/* modal css - start */
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: rgba(0, 0, 0, 0.7); */
}
.modal-container .modal {
  z-index: 1;
  width: 550px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  position: relative;
  animation: animate 0.3s;
  
  box-sizing: border-box;
  justify-content: space-between;

  display: flex;
  flex-direction: column;
  padding: 30px 0px 0px;
  gap: 30px;
  background: #010101;
  border: 1px solid #333333;

  background: rgba(0, 0, 0, 0.7);
}

.wlcheck-modal {
  gap: 0px !important;
}

.modal-button {
  position: absolute;
  top: 7px;
  right: 7px;
  padding: 8px 11px;
  background: crimson;
  color: white;
  font-weight: bold;
  border: none;
  outline: none;
  border-radius: 2px;
  cursor: pointer;
}

@keyframes animate {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

.hideModal {
  z-index: -1;
  opacity: 0;
  animation: hide 0.25s;

  transform: scale(0);
}
@keyframes hide {
  from {
    z-index: 2;
    transform: scale(1);
    opacity: 1;
  }
  to {
    z-index: -1;
    transform: scale(0);
    opacity: 0;
  }
}
.showModal {
  opacity: 1;
  z-index: 2;
  animation: show 0.2s;
  transform: scale(1);
}
@keyframes show {
  from {
    transform: scale(0);
    opacity: 0;
    z-index: -1;
  }
  to {
    transform: scale(1);
    opacity: 1;
    z-index: 2;
  }
}
/* modal css - end */
